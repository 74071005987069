
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { formatDate } from "@/utils/formatters";
import { format, parse } from "fecha";
import type { CalendarAction } from "@/store/modules/appointment.store";

@Component({
    components: {
        ElCard: () => import("@/views/seller/parts/general/el-card.vue"),
        userImage: () => import("@/components/general/user-image.vue"),
    },
})
export default class PageSellerBidCard extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("appointment/actions") actions!: CalendarAction[];

    @Prop({ required: true }) appointment!: Appointment;

    @Prop({ default: false }) detail!: boolean;

    @Prop() tag?: string;

    formatDate = formatDate;

    get action() {
        return this.actions.find((a) => a.id === this.appointment.action_id)?.name ?? "";
    }

    @Emit("click")
    handleClick() {
        return this.appointment;
    }

    isPast(appointment: Appointment) {
        return parse(appointment.planned_at, "isoDateTime")!.getTime() + appointment.duration * 60 * 1000 < new Date().getTime();
    }

    prettifyTime(dateString: string, duration: number) {
        const date = parse(dateString, "isoDateTime");
        const endDate = new Date(date!.getTime() + duration * 60000);
        return `${format(date as Date, "HH:mm")} - ${format(endDate as Date, "HH:mm")}`;
    }

    getInviter() {
        return this.activity.assigned_to.find((a) => a.id === this.appointment!.invited_by);
    }
}
